@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');

:root {
	--primary-color: #3a4052;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	font-family: 'italic', sans-serif;
	line-height: 1.5;
}

a {
	text-decoration: none;
	color: var(--primary-color);
}



.showcase {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #fff;
	/* padding: 0 20px; */
}

.video-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	/* background: var(--primary-color) url('./https://traversymedia.com/downloads/cover.jpg') no-repeat center */
		/* center/cover; */
}

.video-container video {
	min-width: 100%;
	min-height: 100%;
  position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	object-fit: cover;
}

.video-container:after {
	content: '';
	z-index: 1;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.5);
	position: absolute;
}



.content {
	z-index: 2;
	display: flex;
    /* flex-direction: column; */
	justify-content: space-evenly;
}

.btn {
	display: inline-block;
	padding: 10px 30px;
	background: var(--primary-color);
	color: #fff;
	border-radius: 5px;
	border: solid #fff 1px;
	margin-top: 25px;
	opacity: 0.7;
}

.btn:hover {
	transform: scale(0.98);
}

#about {
	padding: 40px;
	text-align: center;
}

#about p {
	font-size: 1.2rem;
	max-width: 600px;
	margin: auto;
}

#about h2 {
	margin: 30px 0;
	color: var(--primary-color);
}

.social a {
	margin: 0 5px;
}


form {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

label {
  font-weight: bold;
  margin-top: 10px;
  display: flex;
  color: black;
}

input[type="text"],
input[type="tel"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 40px;
}

textarea {
height: 100px;
}

input[type="submit"] {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: green;
  color: #fff;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
}

.contact-us {
  /* position: fixed;
  top:12%;
  right:3%; */
  width: 25%;
  background-color: #ccc;
 
  margin: 10px 0px 0px 0px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;

  
}

.logo {
	z-index: 99999 !important;
   
    /* position: absolute; */
	Z-index: 99999 !important;
	display: flex;
	/* top: 26%; */
    /* left: 17%;  */

	
}

.logo img {
	margin-bottom: 16px;
	
    height: 90%;
    width: 35%;
	
}


.main {
	/* margin-bottom:20%;  */
	width: 50%;
	text-align: left;
	margin-top:12%
  }

  .main h1 {
	/* margin:5px; */
	font-weight: 500;
	font-size: 55px;
	line-height: 60px;
  }

  .main p {
	margin:10px;
	font-size: 20px;
	margin-top: 16px;
  }


  .submit_btn {
	width: 100%;
	background-color: #73cdd7;
	color: white;
	height: 40px;
	border-radius:10px;
	border:none;
	cursor:pointer
  }

  .submit_btn:hover {
	background-color: #086d78;
  }

  .footer {
	display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
    
    position: fixed;
    bottom: 0px;
    background-color: white;
    color: black;
    width: inherit;
    height: 3%;
	font-size: 15px;
  }


  textarea#message::placeholder {
    font-size: 13px;
}

@media screen and (max-width: 900px) {
	
.contact-us {
	display: flex;;
	position: relative;
	flex-direction: column;
	top:5rem;
	justify-content: center;

	width: 90%;
	background-color: #ccc;
   align-items:center ;
	margin: 0 auto;
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 10px;
	overflow: scroll;
	bottom: 0px;
	right: 0px;
	margin-bottom: 10px;
	
  }

  .main {
	margin-top:15%; width:60%
  }
  .video-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 190%;
	overflow: scroll;
	/* background: var(--primary-color) url('./https://traversymedia.com/downloads/cover.jpg') no-repeat center */
		/* center/cover; */
}

.content {
	z-index: 2;
	display: flex;
    flex-direction: column;
	justify-content: space-between;
}

.contenth1 {
	font-weight: 300;
	font-size: 25px;
	line-height: 1;
	margin-bottom: 8px;
	margin-top: 325px;
	/* margin-left: 30px;	 */
	
}


.logo {
	z-index: 99999 !important;
   
    position: absolute;
	Z-index: 99999 !important;
	display: flex;

	top: 0;
    left: 0;
	
}


.logo img {
	margin: 10px;
    height: 90%;
    width: 34%;
	margin-left: 6px;
}


.main {
	margin:auto;
	margin-top:14rem; 
	width: 72%;
	
  }
  .main h1 {
	/* margin:5px; */
	font-weight: 500;
	font-size: 30px;
	line-height: 30px;
  }

  .main p {
	margin:10px;
	font-size: 20px;
	margin-top: 16px;
  }

.mdain {
	/* margin-top:15%; */
	 width:60%;
	/* margin-left: 15%; */

	margin:auto;


  }

  
  .footer {
	display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
    
    position: absolute;
    bottom: 0px;
    background-color: white;
    color: black;
    width: inherit;
    height: 4%;
	font-size: 15px;
  }


  }

